import { useCallback, useEffect, useRef } from 'react'
import { GetServerSidePropsContext, NextPage } from 'next'
import dynamic from 'next/dynamic'
import { apiClientSsr } from 'utils/apiClient'
import Metadata from 'shared/components/Metadata'
import Header from 'shared/components/layout/Header'
import { withSessionSsr, createAuthHeader } from 'lib/session'
import { UserBase, UserProfile, UserVerification } from 'types/V3/User'
import { trackAmplitudeCustomEvent } from 'services/analytics/amplitude'
import ProfileHeader, { ProfileHeaderRef } from 'views/profile-page/components/ProfileHeader'
import ProfileTabBar from 'views/profile-page/components/ProfileTabBar'
import { useShowingWelcomeModal } from 'views/profile-page/components/congrats-modal/WelcomePopup'
import withDesktopSidebar from 'shared/utils/withDesktopSidebar'
import useCheckingRole from 'shared/hooks/data-fetching/useCheckingRole'
import { withApiErrorHandled } from 'utils/common'
import { UserDetails } from 'views/profile-page/types'
import { makeProfilePageTitle } from 'views/profile-page/utils/makeProfilePageTitle'
import { getEllipsisText } from 'utils/string'
import { generateArtistJsonLd } from 'views/profile-page/utils/generateArtistJsonLd'

const ArtistSaleData = dynamic(() => import('views/profile-page/components/SalesGraph/ArtistSaleData'))
const ProfileTopArtistsCarousel = dynamic(
  () => import('views/profile-page/components/TopArtistsSection/ProfileTopArtistsCarousel'),
)
const ProfileTopCollectorsCarousel = dynamic(
  () => import('views/profile-page/components/CollectorSection/ProfileTopCollectorsCarousel'),
)
const WelcomePopup = dynamic(() => import('views/profile-page/components/congrats-modal/WelcomePopup'))
const ProfileFeaturedLinks = dynamic(() => import('views/profile-page/components/ProfileFeaturedLinks'))
const ProfileMoodboardsSection = dynamic(() => import('views/profile-page/components/ProfileMoodboardsSection'))
const ProfileTestimonialCarousel = dynamic(() => import('views/profile-page/components/ProfileTestimonialCarousel'), {
  ssr: false,
})
const ProfileArtworksCarousel = dynamic(() => import('views/profile-page/components/ProfileArtworksCarousel'), {
  ssr: false,
})
const ProfileFeaturedCollectionCarousel = dynamic(
  () => import('views/profile-page/components/ProfileFeaturedCollectionCarousel'),
  {
    ssr: false,
  },
)
const ProfileArtistsCarousel = dynamic(() => import('views/profile-page/components/ProfileArtistsCarousel'), {
  ssr: false,
})

const ProfileMomentsCarousel = dynamic(() => import('views/profile-page/components/ProfileMomentsCarousel'), {
  ssr: false,
})
const AboutSection = dynamic(() => import('views/profile-page/components/AboutSection'), {
  ssr: false,
})
const DownloadModal = dynamic(() => import('views/profile-page/components/DownloadModal'), { ssr: false })
const FooterSection = dynamic(() => import('shared/components/layout/FooterSection'), { ssr: false })

const fetchUserProfile = async (username: string, isLoggedIn: boolean, context: GetServerSidePropsContext) => {
  const url = isLoggedIn ? `/v2/users/profile?username=${username}` : `/v2/public/users/profile?username=${username}`
  const response = await withApiErrorHandled<UserDetails>(apiClientSsr.get)(url, {
    headers: createAuthHeader(context.req),
  })

  const { data: userProfile } = response.data
  return userProfile
}

export const getServerSideProps = withSessionSsr(async (context: GetServerSidePropsContext) => {
  const { isLoggedIn, authUser } = context.req
  const username = context.query.username as string
  const isCurrentUser = Boolean(username && username === authUser?.username)

  try {
    const userProfile = await fetchUserProfile(username, isLoggedIn, context)
    const props: UserProfilePageProps = { username, userProfile, isCurrentUser, isLoggedIn }

    return { props }
  } catch (e) {
    console.error(e)
    return { notFound: true }
  }
})

type UserProfilePageProps = {
  username: string
  userProfile: UserProfile & { collectors: Array<UserBase & UserVerification> }
  isCurrentUser: boolean
  isLoggedIn: boolean
}

const UserProfilePage: NextPage<UserProfilePageProps> = (props) => {
  const { userProfile, isCurrentUser, isLoggedIn } = props
  const {
    fullName,
    subtitle,
    username,
    bio,
    coverImage,
    isVerified,
    id,
    firstName,
    flags: { isShowSalesOnProfile },
  } = userProfile
  const { isLinkInCustomCardDisplayed: isCustomCardsVisible } = userProfile.flags

  const pageTitle = makeProfilePageTitle({
    fullName,
    subtitle,
  })

  const { isUserCollector, isUserHenry, isUserCreator, isUserCA, isUserIA } = useCheckingRole(userProfile.role)
  const profileHeaderRef = useRef<ProfileHeaderRef>(null)
  const openCollectorModal = useCallback(() => {
    profileHeaderRef.current?.openCollectorPopup()
  }, [])

  // Effects
  useEffect(() => {
    if (isCurrentUser) return // current user
    const tracking = setTimeout(async () => {
      await trackAmplitudeCustomEvent('profile_viewed', { profile_id: userProfile.id, screen: 'user_profile_page' })
    }, 1000) // wait for 1 second so Amplify can be loaded
    return () => clearTimeout(tracking)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile.id])

  useEffect(() => {
    profileHeaderRef.current?.closeCollectorPopup()
  }, [userProfile.id])

  const { isWelcomeModalVisible, onCloseWelcomeModal } = useShowingWelcomeModal()
  const shouldShowWelcomePopup = isUserCreator && isWelcomeModalVisible && isCurrentUser
  const shouldShowCustomCards = isCustomCardsVisible && !isUserHenry // user shows custom cards, and is not Henry
  const shouldShowTestimonials = isUserCreator || isUserIA || isUserCA // user is creator, IA, or CA
  const shouldShowSalesData = isUserCreator && isVerified && isShowSalesOnProfile // user is creator, verified, and opt to display sales data

  // Render
  return (
    <>
      <Metadata
        title={pageTitle}
        description={bio || ''}
        url={`/u/${username}`}
        thumbnail={coverImage}
        jsonLd={generateArtistJsonLd(userProfile)}
        jsonLdKey="artistJsonLd"
      />
      <Header />
      <div className="mt-20 w-full bg-[#FDFDFD]/70">
        {/* Profile Card */}
        <ProfileHeader
          profile={userProfile}
          isLoggedIn={isLoggedIn}
          isCurrentUser={isCurrentUser}
          isUserCollector={isUserCollector}
          isUserCreator={isUserCreator}
          isUserCA={isUserCA}
          isUserIA={isUserIA}
          isUserHenry={isUserHenry}
          ref={profileHeaderRef}
        />
        <ProfileTabBar
          username={userProfile.username}
          fullname={userProfile.fullName}
          isVerified={userProfile.isVerified}
          isUserCollector={isUserCollector}
          isUserCreator={isUserCreator}
          isUserCA={isUserCA}
          isUserIA={isUserIA}
          isUserHenry={isUserHenry}
          isCurrentUser={isCurrentUser}
          hasArtworks={userProfile.hasArtworks}
        />
        <div className="container space-y-6 py-6 lg:space-y-8 lg:py-8">
          {/* artworks */}
          {isUserCreator && (
            <ProfileArtworksCarousel username={username} isCurrentUser={isCurrentUser} isLoggedIn={isLoggedIn} />
          )}

          {/* artists */}
          {isUserCA && (
            <ProfileArtistsCarousel username={username} isCurrentUser={isCurrentUser} artistFirstName={firstName} />
          )}

          {/* collector */}
          {isUserCollector && (
            <ProfileFeaturedCollectionCarousel
              username={username}
              isCurrentUser={isCurrentUser}
              isLoggedIn={isLoggedIn}
            />
          )}

          {/* moments */}
          <ProfileMomentsCarousel isCurrentUser={isCurrentUser} username={username} isLoggedIn={isLoggedIn} />

          {/* moodboards */}
          <ProfileMoodboardsSection username={username} isCurrentUser={isCurrentUser} />

          {/* collectors */}
          {isUserCreator && (
            <ProfileTopCollectorsCarousel
              firstName={userProfile.firstName}
              fullName={userProfile.fullName}
              username={username}
              openCollectorModal={openCollectorModal}
              isCurrentUser={isCurrentUser}
            />
          )}

          {/* artists */}
          {isUserCollector && (
            <ProfileTopArtistsCarousel
              firstName={userProfile.firstName}
              fullName={userProfile.fullName}
              username={username}
              isCurrentUser={isCurrentUser}
            />
          )}

          {/* about */}
          <AboutSection userProfile={userProfile} isCurrentUser={isCurrentUser} />

          {/* custom cards */}
          {shouldShowCustomCards && <ProfileFeaturedLinks username={username} />}

          {/* sales graph */}
          {shouldShowSalesData && (
            <>
              <div className="h-10" />
              <ArtistSaleData
                firstName={getEllipsisText(firstName || fullName.split(' ')[0], 15)}
                userId={id}
                isCurrentUser={isCurrentUser}
              />
            </>
          )}

          {/* testimonials */}
          {shouldShowTestimonials && <ProfileTestimonialCarousel username={username} />}
        </div>
      </div>
      <FooterSection />
      {/* show this if user is not logged in */}
      <DownloadModal show={!isLoggedIn} />
      {shouldShowWelcomePopup && <WelcomePopup onCloseClick={onCloseWelcomeModal} />}
    </>
  )
}

export default withDesktopSidebar(UserProfilePage)
